import React, {useState, useEffect} from "react";
import Col from "react-bootstrap/Col";

export const Search = (props) => {

    const [searchField, setSearchField] = useState([""]);
    const url = process.env.REACT_APP_BASEURL + "/search/"
    const [searchUrl, setSearchUrl] = useState(url);

    
    function handleSearch(event) {
        setSearchField(event.target.value);
        

        const processedDiscourse = props.discourseData.filter(
            discourseEntry => {

                const topic = discourseEntry.topic_title.toLowerCase();
                const searchEntry = searchField.toString().toLowerCase();

                if (searchEntry) {
                    return topic.includes(searchEntry);
                } else {
                    return true;
                }

            }
        )
        // updata data that was filtered by criteria above.
        props.updateDiscourseData(processedDiscourse);
      }

    return (
    <Col>
    {/* <form >
    <input className="search-box" type="search" 
        placeholder="Search mockup"
        value={searchField}
            onChange={handleSearch} 
        />
    </form> */}
    <small>
    <a href={searchUrl}>How to Search</a>
    </small>
    </Col>  
    )
}