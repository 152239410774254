import React, {useState, useEffect} from "react";
import axios from "axios";
import Col from "react-bootstrap/Col";
import {DocumentationEntry} from "../DocumentationEntry/DocumentationEntry";


export const DocumentationPanel = () => {
    const [ allData, updateData ] = useState([]);
	
	useEffect(() => {
        const url = process.env.REACT_APP_BASEURL + "/api/doc";
		axios
        // put the environmental variable in .env in project root
        .get(url)
		.then((res) => {
			const currentData = res.data;
            updateData(currentData);
		})
		.catch((err) => console.log(err));
    }, []);

    return (<div>
        <strong>Documentation</strong>
        <Col>
            {allData.map((doc, index) => 
                doc.public && 
                <DocumentationEntry 
                key={index}
                title={doc.title}
                doc_link={doc.link_to_external_doc}
                description={doc.description}
                icon={doc.icon}
                full_doc={doc.this_is_full_doc}
                pk={doc.pk}
                />
            )}
        </Col>

    </div>)
}