import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {DiscoursePost} from '../DiscoursePost.component';

export const DiscoursePanel = (props) => {

    return (
    <Col>

        <Row>
        <strong>Discussion</strong>
        </Row>

        {props.discourseData.map(recentPost => (
            <DiscoursePost username={recentPost.username}
                title={recentPost.topic_title}
                key={recentPost.id}
                slug={recentPost.topic_slug} 
                topicId={recentPost.topic_id}
                postNumber={recentPost.post_number}   
                categoryId={recentPost.category_id} 
                allCategories={props.discourseCategories}
                />
        ))}

    </Col>)
}
