import React from "react";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col';
import "./DiscoursePost.style.css";

export const DiscoursePost = (props) => {


    const url = "https://community.theta360.guide/t/" + props.slug + "/" + props.topicId + "/" + props.postNumber;
    // console.log(currentCategory);
    let iconUrl = "";
    props.allCategories.forEach(element => {
        // console.log(element.category_id);
        if (element.category_id === props.categoryId) {
            iconUrl = element.category_icon;

        } 
    }); 

return(
    <Col>
      <Row className="entry border rounded card-frame transition-3d-hover mb-3 ">
      <Col lg="auto" md="auto">
					<img src={iconUrl} alt="category icon" />
    </Col>

      <Col className="info">

      <Row className="h6 text-dark mb-1">

        <a href={url}>
        {props.title}</a>  
        
        <small className="d-block text-muted">

         by {props.username}
         </small>
        </Row> 
        </Col>
    </Row>
    </Col>
)}