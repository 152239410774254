import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './AppListEntry.css';

export const AppListEntry = (props) => {
	return (
		<Col>
			<Row className="entry border rounded card-frame transition-3d-hover mb-3 ">
				<Col lg="auto" md="auto">
					<img src={props.logo} alt="logo" />
				</Col>
				<Col className="info">
        <Row className="h6 text-dark mb-1">
          <a href={props.url}>{props.name}</a>
        </Row>
        <Row>
          <small className="d-block text-muted">

          {props.description}
          </small>
        </Row>



				</Col>
			</Row>
		</Col>
	);
};
