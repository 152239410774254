import React from "react";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col';
import "./DocumentationEntry.css"

export const DocumentationEntry = (props) => {
    const baseUrl = process.env.REACT_APP_BASEURL + "/doc/article/"

    return (
        <Col>
        <Row className="entry border rounded card-frame transition-3d-hover mb-3 ">
            {props.icon && 
                <Col lg="auto" md="auto">
					<img src={props.icon} alt="icon" />
		        </Col> 
                }
        <Col className="info">
        <Row className="h6 text-dark mb-1">

        <a href={props.full_doc ? baseUrl + props.pk : props.doc_link }>
        {props.title}</a>  
        </Row>

        <Row>
          <small className="d-block text-muted">

          {props.description}
          </small>
        </Row>
         </Col>
        </Row>
        </Col>
    )

}