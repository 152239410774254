import React from "react";

function Contact() {
    return (
        <div>
<footer className="container">
    <div className="row pt-3">

    </div>
  <div className="row space-2">
    <div className="col-md-4  mb-7 mb-md-0">
      <div className="media">
        <span className="btn btn-icon btn-soft-primary rounded-circle mr-3">
          <span className="fas fa-comments btn-icon__inner"></span>
        </span>
        <a href="https://community.theta360.guide">
        <div className="media-body">
          <h4 className="h6 text-secondary font-weight-normal mb-0">
          Community</h4>
          <span className="d-block font-size-1">1,200 topics to learn
          more about software development and 360 photography
          </span>
        </div>
        </a>
      </div>
    </div>

    <div className="col-md-4 mb-7 mb-md-0">

      <div className="media">

        <span className="btn btn-icon btn-soft-primary rounded-circle mr-3">
          <span className="fas fa-images btn-icon__inner"></span>
        </span>
        <a href="https://gallery.theta360.guide">
        <div className="media-body">
          <h4 className="h6 text-secondary font-weight-normal mb-0">Image technique gallery</h4>
          <span className="d-block font-size-1">Sample images and explanation of techniques</span>
        </div>
        </a>

      </div>
    </div>

    <div className="col-md-4 ">
      <div className="media">
        <span className="btn btn-icon btn-soft-primary rounded-circle mr-3">
          <span className="fas fa-map-marker-alt btn-icon__inner"></span>
        </span>
        <a href="https://theta360.guide/app">       
        <div className="media-body">
          <h4 className="h6 text-secondary font-weight-normal mb-0">App Listing</h4>
          <span className="d-block font-size-1">
          200+ apps to improve your THETA experience</span>
        </div>
        </a>
      </div>

    </div>
  </div>

  <hr className="my-0" />

  <div className="row space-2">
    <div className="col-6 col-lg-3 mb-7 mb-lg-0">

      <p className="small text-muted">© <a href="https://oppkey.com/">Oppkey</a> All rights reserved.</p>
      <p className="small text-muted"><a href="https://theta360developers.github.io/">old site</a></p>

    </div>

    <div className="col-6 col-lg-3 mb-7 mb-lg-0">



    </div>

    <div className="col-6 col-lg-3 mb-7 mb-lg-0">


    </div>

    <div className="col-6 col-lg-3 mb-7 mb-lg-0">
      <h4 className="font-size-1 font-weight-normal text-secondary">Connect</h4>


      <ul className="list-inline mb-0">
        <li className="list-inline-item mb-3">
          <a className="btn btn-sm btn-icon btn-soft-primary rounded" href="https://youtube.com/theta360developers">
            <span className="fab fa-youtube btn-icon__inner"></span>
          </a>
        </li>        
        <li className="list-inline-item mb-3">
          <a className="btn btn-sm btn-icon btn-soft-primary rounded" href="https://twitter.com/theta360dev">
            <span className="fab fa-twitter btn-icon__inner"></span>
          </a>
        </li>        
        <li className="list-inline-item mb-3">
          <a className="btn btn-sm btn-icon btn-soft-primary rounded" href="https://www.facebook.com/theta360guide/">
            <span className="fab fa-facebook-f btn-icon__inner"></span>
          </a>
        </li>

        <li className="list-inline-item mb-3">
          <a className="btn btn-sm btn-icon btn-soft-primary rounded" href="https://github.com/theta360developers/">
            <span className="fab fa-github btn-icon__inner"></span>
          </a>
        </li>
      </ul>

    </div>
  </div>
</footer>



{/* last div for the return */}
        </div>
)
}

export default Contact;