import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./SiteInfo.css";

export const SiteInfo = () => {

    return (
        <Col className="pt-5">

            <Row className="font-size-1">
                <Col className="mr-3">
                    <Row>
                        <span className="font-size-1">
                            <strong>Independent site run by Oppkey </strong>
                        </span>
                    </Row>
                    <Row>
                        <span className="font-size-1">
                            We receive sponsorship from
                            RICOH. Oppkey is a developer relations
                            consulting firm managed by
                            Jesse Casman (jcasman@oppkey.com) and Craig Oda (craig@oppkey.com). We are
                            located in San Francisco and Palo Alto.
                            <a href="http://oppkey.com/"> more </a>
                        </span>
                    </Row>
                </Col>
                {/* end column 1 */}

                <Col className="mr-3">
                    <Row>
                        <span className="font-size-1">
                            <strong>Free assistance to partner with RICOH THETA</strong>
                        </span>
                    </Row>
                    <Row>
                        <span className="font-size-1">
                            Oppkey is under contract with RICOH to assist independent developers and companies
                            in building applications for the RICOH THETA and bringing
                            your products to market. We help with documentation, functionality testing,
                            and marketing.
                        <a href="https://theta360.guide/partner/"> more </a>
                        </span>
                    </Row>
                </Col>
                {/* end column 2 */}

                <Col className="mr-3">
                    <Row>
                        <span className="font-size-1">
                            <strong>Free marketing and technical services</strong>
                        </span>
                    </Row>
                    <Row>
                        <span className="font-size-1">
                            Get help from the largest independent community for RICOH THETA.
                            List your product on our site.  Run co-marketing campaigns. Meet
                            developers and end users at theta360.guide events.
                        <a href="https://theta360.guide/guide/"> more </a>
                        </span>
                    </Row>
                </Col>
                {/* end column 3 */}

            </Row>
        </Col>
    )
}