import React,  {useEffect, useState} from 'react';
import axios from "axios";

import './App.css';
import MainContentPanel from './MainContentPanel/MainContentPanel';
import Container from 'react-bootstrap/Container';
import Footer from './Footer'
import {Title} from "./TopPanel/TitlePanel/Title.component";



function App() {

  // begin pulling discourse data
  // move to separate file in the future.
  const [ discourseData, updateDiscourseData ] = useState([]);

  useEffect(() => {
    const apiEndPoint = process.env.REACT_APP_DISCOURSE_POSTS;
        /* const apiEndPoint = 'https://jsonplaceholder.typicode.com/posts';  */
    console.log("trying to get API on " + apiEndPoint);
    axios
    .get(apiEndPoint)
    .then((res) => {
        const latestPosts = res.data.latest_posts.slice(0, 12);
        console.log("got api on " + apiEndPoint);
        updateDiscourseData(latestPosts);
    })
    .catch((err) => console.log(err));
  }, []);

  // begin Discourse category section
  const [ discourseCategories, updateDiscourseCategories ] = useState([]);
	
	useEffect(() => {
        const url = process.env.REACT_APP_BASEURL + "/api/category";
        console.log("trying to get API on " + url);
		axios
        // put the environmental variable in .env in project root
        .get(url)
		.then((res) => {
			const currentData = res.data;
            updateDiscourseCategories(currentData);
            console.log("got api for " + url);
		})
		.catch((err) => console.log(err));
    }, []);


  return (
    <div>
    <Container className="p-5">
      <Title updateDiscourseData={updateDiscourseData} 
        discourseData={discourseData} />
      <MainContentPanel 
        discourseData={discourseData} 
        discourseCategories={discourseCategories} />
      <Footer />
    </Container>
    </div>
  );
}

export default App;
