import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./AdditionalResources.css";


export const AdditionalResources = () => (
    <Container>
        <Row>
        {/* begin column for the first column of resources */}
        <Col>
        <Row>
             <strong>Guides</strong>
        </Row>

        <Container>
        <Row>
            <span className="font-size-1">
            <a href="https://theta360developers.github.io/community-document/live-streaming.html">Live Streaming</a> (THETA S, V and RICOH R)
            </span>
        </Row>
        <Row>
            <span className="font-size-1">
            <a href="https://theta360developers.github.io/thetamedia/">Media</a> (THETA S)
            </span>
        </Row>

        <Row>
            <span className="font-size-1">
            <a href="https://theta360developers.github.io/plugin/">Plug-ins</a> (THETA V)
            </span>
        </Row>

        <Row>
            <span className="font-size-1">
            <a href="https://theta360developers.github.io/usbstorageplugin/">External Storage</a> (THETA V)
            </span>
        </Row>     

          
        </Container>
        {/* end of first column             */}
        </Col>

        {/* keep each column in the row */}
        {/* begin middle column */}
        <Col>
        <Row>
             <strong>USB API Examples</strong>
        </Row>
        <Container>
        <Row>
            <span className="font-size-1">
            <a href="https://community.theta360.guide/t/ricoh-theta-api-over-usb-cable-z1-v-s-sc-models/65">Overview by Community</a>
            </span>
        </Row>  
        <Row>
            <span className="font-size-1">
            <a href="https://community.theta360.guide/t/live-leaf-peeping-digital-repeat-photography/507">Virtual Forest by Koen Huftkens</a>
            </span>
        </Row>  
        <Row>
            <span className="font-size-1">
            <a href="https://github.com/theta360developers/tlapser360">tlapser360 by Jason Charcalla</a>
            </span>
        </Row>  


        </Container>
        {/* end of middle column */}
        </Col>
         {/* change column change column change column change column */}
        {/* begin right column */}
        <Col>
        <Row>
             <strong>Unity</strong>
        </Row>
        <Container>
        <Row>
            <span className="font-size-1">
            <a href="hter-usb-cable-z1-v-s-sc-models/65">Stream to Unity Skybox</a> (THETA Z1)
            </span>
        </Row>  
        <Row>
            <span className="font-size-1">
            <a href="">Stream to HTC Vive and SteamVR</a> (THETA V)
            </span>
        </Row>   


        </Container>        {/* end of right column */}
        </Col>
        </Row>

    </Container>
)