import React from "react";
import Col from "react-bootstrap/Col";

export const CameraPromo = (props) => {

    return (
        <Col>
        

        <small>
        <span className="pr-1">
        Models: 
        </span>
        <span className="pr-2">
        <a href={props.z1}>Z1</a> 
        </span>
        <span className="pr-2">
        <a href={props.v}>V</a>
        </span>
        <span className="pr-2">
        <a href={props.sc2}>SC2</a>
        </span> 
        </small>
        </Col>  
        )

}