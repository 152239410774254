import React, {useState, useEffect} from "react";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AppListEntry} from "../AppListEntry/AppListEntry";


export const AppListPanel = () => {
    const [ allData, updateData ] = useState([]);
	
	useEffect(() => {
        const url = process.env.REACT_APP_BASEURL + "/api/app";
        console.log("trying to get API on " + url);
		axios
        // put the environmental variable in .env in project root
        .get(url)
		.then((res) => {
			const currentData = res.data;
            updateData(currentData);
		})
		.catch((err) => console.log(err));
    }, []);

    return (<div>
        <strong>Apps</strong>
        <Col>
            {allData.map((app, index) => 
                <AppListEntry 
                key={index}
                name={app.app_name}
                url={app.guide_url}
                description={app.public_summary}
                logo={app.company_logo}
                />
            )}
        </Col>
    </div>)
}
