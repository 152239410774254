import React, {useState, useEffect} from "react";
import axios from "axios";
import logo from "./theta-logo.png";
import "./Title.style.css";
import {CameraPromo} from "./CameraPromo/CameraPromo.component"
import {Search} from "../Search/Search.component";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"

export const Title = (props) => {

    const [ promoBanner, setPromoBanner ] = useState({
        text: "",
        url: "",
        amazon_z1: "",
        amazon_v: "",
        amazon_sc2: "",
        amazon_s: "",
        amazon_sc: ""
    });

    useEffect(() => {
      const apiEndPoint = process.env.REACT_APP_BASEURL + '/api/promo/';
      console.log("trying to get API on " + apiEndPoint);
      axios
      .get(apiEndPoint)
      .then(res => {
          const currentData = res.data;
        console.log("got api on " + apiEndPoint);
        const promoData = currentData[0]

        setPromoBanner({
            text: promoData.main_top_promotion_display,
            url: promoData.main_top_promotion_url,
            amazon_z1: promoData.amazon_z1,
            amazon_v: promoData.amazon_v,
            amazon_sc2: promoData.amazon_sc2,
            amazon_s: promoData.amazon_s,
            amazon_sc: promoData.amazon_sc,
            riac_z1: promoData.riac_z1,
            riac_v: promoData.riac_v,
            riac_sc2: promoData.riac_sc2                        

        });
      })
      .catch((err) => console.log(err));
    }, []);

    return (
    <div>
    <Row>
    <Col>
    <img className="logo" alt="logo" src={logo} />    
    </Col>
    <Col> 
    <small className="text-muted">
    <a href={promoBanner.url}>
    {promoBanner.text}
    </a>        </small>
    </Col>
    <CameraPromo 
      z1={promoBanner.riac_z1}
      v={promoBanner.riac_v}
      sc2={promoBanner.riac_sc2}
       />
      <Search discourseData={props.discourseData}
        updateDiscourseData={props.updateDiscourseData} />
    {/* <Col className="text-muted">
    Login mockup
    </Col> */}
    </Row>
    <Row>
        <hr />
    </Row>
    </div>
)}