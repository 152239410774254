import React from "react";
import Contact from "./Contact";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SiteInfo} from "./SiteInfo/SiteInfo";
import {AdditionalResources} from "./AdditionalResources/AdditionalResources";

function PartnerFooter() {
    return ( 
    <Col>
<hr />
        <Row>
            <AdditionalResources />
        </Row>
        <Row>
            <SiteInfo />
        </Row>

        <Row>
            <Contact />
        </Row>
    </Col>)
}

export default PartnerFooter;