import React from "react";
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import {DiscoursePanel} from "../Discourse/DiscoursePanel/DiscoursePanel.component";
import "./MainContentPanel.css";
import {DocumentationPanel} from "../Documentation/DocumentationPanel/DocumentationPanel.component";
import {AppListPanel} from "../AppList/AppListPanel/AppListPanel.component";

function MainContentPanel(props) {
  
    return (
      <Row>
      <Col l={4}>
        <DiscoursePanel discourseData={props.discourseData} 
          discourseCategories={props.discourseCategories}
        />
      </Col>
      <Col l={6}>
        <DocumentationPanel />
      </Col>
      <Col l={6}>
        <AppListPanel />
      </Col>

      </Row>

    )
}

export default MainContentPanel;